<template>
  <div>
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          x-small
          v-if="item != undefined"
          color="red"
          v-text="get_duration(item)"
        >
        </v-icon>
      </template>
      <span class="white--text">
        Start Time :
        {{
          item != undefined
            ? new Date(item.first_swipe * 1000).toLocaleTimeString()
            : ""
        }}<br />End Time :
        {{
          item != undefined
            ? new Date(item.last_swipe * 1000).toLocaleTimeString()
            : ""
        }}<br />
        Duration :
        {{
          item != undefined && item.total_duration != undefined
            ? item.total_duration.split(":")[0] +
              "h" +
              " " +
              item.total_duration.split(":")[1] +
              "m"
            : ""
        }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    color: "",
  }),
  props: {
    item: Object,
  },
  methods: {
    get_duration(item) {
      if (item.attendence == "P") {
        if (
          item.organisation_end_epoch != undefined &&
          item.organisation_start_epoch != undefined &&
          item.first_swipe != undefined &&
          item.last_swipe != undefined
        ) {
          if (item.first_swipe != item.last_swipe) {
            let org_time =
              item.organisation_end_epoch - item.organisation_start_epoch;
            let emp_time = item.last_swipe - item.first_swipe;
            if (emp_time < org_time) {
              return "mdi-checkbox-blank-circle";
            } else {
              return "";
            }
          }
        }
      }
    },
  },
};
</script>

<style>
</style>